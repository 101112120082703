<template>
  <v-app>
    <nav>
      <router-link to="/">Home</router-link>
      |
      <a v-on:click="login_dialog=true" v-if="!login_status">Login</a>
      |
      <a v-on:click="register_dialog=true" v-if="!login_status">Register</a>
      <a v-on:click="logout()" v-if="login_status">{{ login_status.email }} (Logout)</a>
    </nav>
    <router-view/>

    <v-footer dark padless fixed>
      <v-card class="flex" flat tile>
        <v-card-text class="py-2 white--text text-center">
          {{ new Date().getFullYear() }} — <strong>HONGKONG JT TRADING CO., LIMITED</strong>
        </v-card-text>
      </v-card>
    </v-footer>

    <AlertBar ref="alertBar"/>

    <v-dialog v-model="register_dialog" persistent max-width="600px">
      <v-card>
        <v-card-title><span class="text-h5">User Register</span></v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="Legal first name*" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="Legal middle name" hint="example of helper text only on focus"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="Legal last name*" hint="example of persistent helper text" persistent-hint
                              required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field label="Email*" v-model="req_body.email" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field label="Password*" v-model="req_body.password" type="password" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                    :items="['0-17', '18-29', '30-54', '54+']"
                    label="Age*"
                    required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete
                    :items="['Action','Arcade & Rhythm','Fighting & Martial Arts','First-Person Shooter','Hack & Slash','Platformer & Runner','Third-Person Shooter','shmup','Adventure','Adventure RPG','Casual','Hidden Object','Metroidvania','Puzzle','Story-Rich','Visual Novel','Role-Playing','Action RPG','Adventure RPG','JRPG','Party-Based','Rogue-Like','Strategy RPG','Turn-Based','Simulation','Building & Automation','Dating','Farming & Crafting','Hobby & Job','Life & Immersive','Sandbox & Physics','Space & Flight','Strategy','Card & Board','City & Settlement','Grand & 4X','Military','Real-Time Strategy','Tower Defense','Turn-Based Strategy','Sports & Racing','All Sports','Fishing & Hunting','Individual Sports','Racing','Racing Sim','Sports Sim','Team Sports',]"
                    label="Interests Gaming Type"
                    multiple
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="register_dialog = false">
            Close
          </v-btn>
          <v-btn color="primary" :loading="register_loading" @click="register_user()">
            Register
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="login_dialog" persistent max-width="300px">
      <v-card>
        <v-card-title><span class="text-h5">User Register</span></v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field dense label="Email*" v-model="req_body.email" required></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field dense label="Password*" v-model="req_body.password" type="password"
                              required></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="login_dialog = false">
            Close
          </v-btn>
          <v-btn color="primary" :loading="login_loading" @click="login_user()">
            Login
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>

import AlertBar from "@/components/AlertBar";
import Req from "@/func/request";

export default {
  components: {AlertBar},

  data: () => ({
    login_status: null,
    register_dialog: false,
    register_loading: false,
    login_dialog: false,
    login_loading: false,
    req_body: {merchant: 'jt-trading', email: null, password: null},
  }),

  provide() {
    return {
      send_alert: this.send_alert
    }
  },

  created() {
    this.get_user_info()
  },

  methods: {
    send_alert(msg) {
      this.$refs.alertBar.open(msg)
    },
    get_user_info() {
      Req.doHTTPGet("/spv-api/v1/info",
          res => {
            if (res.code !== 0) {
              this.login_status = null
              return
            }
            this.login_status = res.data
          }, () => this.send_alert("Network Error"))
    },
    register_user() {
      if (!Req.is_email(this.req_body.email)) return this.send_alert("email format error")
      if (!this.req_body.password) return this.send_alert("password can not be empty")

      this.register_loading = true
      Req.doHTTPPost("/spv-api/v1/register", this.req_body,
          res => {
            this.register_loading = false
            if (res.code !== 0) return this.send_alert(res.message)
            this.send_alert("Register Success")
            this.req_body.password = null
            this.register_dialog = false
            this.login_dialog = true
          }, () => {
            this.register_loading = false
            this.send_alert("Network Error")
          })
    },
    login_user() {
      if (!Req.is_email(this.req_body.email)) return this.send_alert("email format error")
      if (!this.req_body.password) return this.send_alert("password can not be empty")

      this.login_loading = true
      Req.doHTTPPost("/spv-api/v1/login", this.req_body,
          res => {
            this.login_loading = false
            if (res.code !== 0) return this.send_alert(res.message)
            console.log(res.data.access_token)
            localStorage.setItem('token', res.data.access_token)
            this.send_alert("Login Success")
            this.login_dialog = false
            this.get_user_info()
          }, () => {
            this.login_loading = false
            this.send_alert("Network Error")
          })
    },
    logout() {
      localStorage.removeItem('token')
      this.login_status = null
      this.send_alert('Logout success')
    }
  },
}
</script>

<style lang="scss">

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
